<template>
  <div>
    <NavigationBar titel="On-/Offboarding" isPreview></NavigationBar>
    <section>
      <TippCard hintID="Mitgliederverwaltung_OnOffboarding1_V1" text="">
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-list"
                title="Prozesse"
                :actions="actions"
              ></Subheader>
              <div class="mt-7"></div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
// import VueApexCharts from "vue-apexcharts";
// import { MEMBER_MANAGEMENT } from "@/store/modules.js";
// import { GET_PERFORMANCE_TARGETS } from "@/store/action-types.js";
// import { MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-on-offboarding",
  components: {
    NavigationBar,
    TippCard,
    Subheader,
    // apexchart: VueApexCharts,
  },
  data() {
    return {
      actions: [
        // {
        //   title: "Erstellen",
        //   icon: "mdi-plus",
        //   permission: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create}`,
        //   actionStyle: "textBtn",
        //   function: () => {
        //     this.$router.push({
        //       name: "member-management-performance-targets-new",
        //       params: {
        //         organizationId: this.$route.params.organizationId,
        //       },
        //     });
        //   },
        // },
      ],
    };
  },
  computed: {
    // performanceTargets() {
    //   return this.$store.state.memberManagement.performanceTargets;
    // },
  },
  created() {
    // this.initialize();
  },
  methods: {
    initialize() {
      //   this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`, {
      //     organizationId: this.$route.params.organizationId,
      //   });
    },
  },
};
</script>
